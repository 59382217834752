define("discourse/plugins/discourse-subscriptions/discourse/helpers/format-currency", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.helper(function (params) {
    let currencySign;
    switch (params[0]) {
      case "EUR":
      case "eur":
        currencySign = "€";
        break;
      case "GBP":
      case "gbp":
        currencySign = "£";
        break;
      case "INR":
      case "inr":
        currencySign = "₹";
        break;
      case "BRL":
      case "brl":
        currencySign = "R$";
        break;
      case "DKK":
      case "dkk":
        currencySign = "DKK";
        break;
      case "SGD":
      case "sgd":
        currencySign = "S$";
        break;
      default:
        currencySign = "$";
    }
    return currencySign + params[1];
  });
});