define("discourse/plugins/discourse-subscriptions/discourse/components/subscribe-card", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="card-element"></div>
  */
  {
    "id": "JqWa6thI",
    "block": "[[[10,0],[14,1,\"card-element\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/subscribe-card.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    didInsertElement() {
      this._super(...arguments);
      this.cardElement.mount("#card-element");
      this.setCardElementStyles();
    },
    setCardElementStyles() {
      const root = document.querySelector(":root");
      const computedStyle = getComputedStyle(root);
      const primaryColor = computedStyle.getPropertyValue("--primary");
      const placeholderColor = computedStyle.getPropertyValue("--secondary-medium");
      this.cardElement.update({
        style: {
          base: {
            color: primaryColor,
            "::placeholder": {
              color: placeholderColor
            }
          }
        }
      });
    },
    didDestroyElement() {}
  }));
});