define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-coupons", ["exports", "@ember/controller", "discourse/plugins/discourse-subscriptions/discourse/models/admin-coupon", "discourse/lib/ajax-error"], function (_exports, _controller, _adminCoupon, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    creating: null,
    actions: {
      openCreateForm() {
        this.set("creating", true);
      },
      closeCreateForm() {
        this.set("creating", false);
      },
      createNewCoupon(params) {
        _adminCoupon.default.save(params).then(() => {
          this.send("closeCreateForm");
          this.send("reloadModel");
        }).catch(_ajaxError.popupAjaxError);
      },
      deleteCoupon(coupon) {
        _adminCoupon.default.destroy(coupon).then(() => {
          this.send("reloadModel");
        }).catch(_ajaxError.popupAjaxError);
      },
      toggleActive(coupon) {
        const couponData = {
          id: coupon.id,
          active: !coupon.active
        };
        _adminCoupon.default.update(couponData).then(() => {
          this.send("reloadModel");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});