define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-products-show", ["exports", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/admin-product", "discourse/plugins/discourse-subscriptions/discourse/models/admin-plan", "I18n", "rsvp", "@ember/object", "@ember/service"], function (_exports, _route, _adminProduct, _adminPlan, _I18n, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _route.default.extend((_obj = {
    dialog: (0, _service.inject)(),
    model(params) {
      const product_id = params["product-id"];
      let product;
      let plans = [];
      if (product_id === "new") {
        product = _adminProduct.default.create({
          active: false,
          isNew: true
        });
      } else {
        product = _adminProduct.default.find(product_id);
        plans = _adminPlan.default.findAll({
          product_id
        });
      }
      return (0, _rsvp.hash)({
        plans,
        product
      });
    },
    destroyPlan(plan) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_subscriptions.admin.plans.operations.destroy.confirm"),
        didConfirm: () => {
          plan.destroy().then(() => {
            this.controllerFor("adminPluginsDiscourseSubscriptionsProductsShow").get("model.plans").removeObject(plan);
          }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n")));
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "destroyPlan", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyPlan"), _obj)), _obj));
});