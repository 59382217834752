define("discourse/plugins/discourse-subscriptions/discourse/models/admin-product", ["exports", "discourse/lib/ajax", "@ember/object"], function (_exports, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminProduct = _object.default.extend({
    isNew: false,
    metadata: {},
    destroy() {
      return (0, _ajax.ajax)(`/s/admin/products/${this.id}`, {
        method: "delete"
      });
    },
    save() {
      const data = {
        name: this.name,
        statement_descriptor: this.statement_descriptor,
        metadata: this.metadata,
        active: this.active
      };
      return (0, _ajax.ajax)("/s/admin/products", {
        method: "post",
        data
      }).then(product => AdminProduct.create(product));
    },
    update() {
      const data = {
        name: this.name,
        statement_descriptor: this.statement_descriptor,
        metadata: this.metadata,
        active: this.active
      };
      return (0, _ajax.ajax)(`/s/admin/products/${this.id}`, {
        method: "patch",
        data
      });
    }
  });
  AdminProduct.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/s/admin/products", {
        method: "get"
      }).then(result => {
        if (result === null) {
          return {
            unconfigured: true
          };
        }
        return result.map(product => AdminProduct.create(product));
      });
    },
    find(id) {
      return (0, _ajax.ajax)(`/s/admin/products/${id}`, {
        method: "get"
      }).then(product => AdminProduct.create(product));
    }
  });
  var _default = _exports.default = AdminProduct;
});