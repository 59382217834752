define("discourse/plugins/discourse-subscriptions/discourse/models/user-subscription", ["exports", "@ember/object", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/plugins/discourse-subscriptions/discourse/models/plan", "I18n"], function (_exports, _object, _decorators, _ajax, _plan, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const UserSubscription = _object.default.extend((_dec = (0, _decorators.default)("status"), _dec2 = (0, _decorators.default)("current_period_end", "canceled_at"), _dec3 = (0, _decorators.default)("discount"), (_obj = {
    canceled(status) {
      return status === "canceled";
    },
    endDate(current_period_end, canceled_at) {
      if (!canceled_at) {
        return moment.unix(current_period_end).format("LL");
      } else {
        return _I18n.default.t("discourse_subscriptions.user.subscriptions.cancelled");
      }
    },
    discounted(discount) {
      if (discount) {
        const amount_off = discount.coupon.amount_off;
        const percent_off = discount.coupon.percent_off;
        if (amount_off) {
          return `${parseFloat(amount_off * 0.01).toFixed(2)}`;
        } else if (percent_off) {
          return `${percent_off}%`;
        }
      } else {
        return _I18n.default.t("no_value");
      }
    },
    destroy() {
      return (0, _ajax.ajax)(`/s/user/subscriptions/${this.id}`, {
        method: "delete"
      }).then(result => UserSubscription.create(result));
    }
  }, (_applyDecoratedDescriptor(_obj, "canceled", [_dec], Object.getOwnPropertyDescriptor(_obj, "canceled"), _obj), _applyDecoratedDescriptor(_obj, "endDate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "endDate"), _obj), _applyDecoratedDescriptor(_obj, "discounted", [_dec3], Object.getOwnPropertyDescriptor(_obj, "discounted"), _obj)), _obj)));
  UserSubscription.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/s/user/subscriptions", {
        method: "get"
      }).then(result => result.map(subscription => {
        subscription.plan = _plan.default.create(subscription.plan);
        return UserSubscription.create(subscription);
      }));
    }
  });
  var _default = _exports.default = UserSubscription;
});