define("discourse/plugins/discourse-subscriptions/discourse/models/subscription", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax", "@ember/object"], function (_exports, _decorators, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const Subscription = _object.default.extend((_dec = (0, _decorators.default)("status"), (_obj = {
    canceled(status) {
      return status === "canceled";
    },
    save() {
      const data = {
        source: this.source,
        plan: this.plan,
        promo: this.promo,
        cardholder_name: this.cardholderName,
        cardholder_address: this.cardholderAddress
      };
      return (0, _ajax.ajax)("/s/create", {
        method: "post",
        data
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "canceled", [_dec], Object.getOwnPropertyDescriptor(_obj, "canceled"), _obj)), _obj)));
  Subscription.reopenClass({
    show(id) {
      return (0, _ajax.ajax)(`/s/${id}`, {
        method: "get"
      });
    }
  });
  var _default = _exports.default = Subscription;
});