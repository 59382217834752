define("discourse/plugins/discourse-subscriptions/discourse/routes/user-billing", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    templateName: "user/billing",
    setupController(controller, model) {
      if (this.currentUser.id !== this.modelFor("user").id) {
        this.replaceWith("userActivity");
      } else {
        controller.setProperties({
          model
        });
      }
    }
  });
});