define("discourse/plugins/discourse-subscriptions/discourse/models/plan", ["exports", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const Plan = _object.default.extend((_dec = (0, _decorators.default)("recurring.interval"), _dec2 = (0, _decorators.default)("amountDollars", "currency", "billingInterval"), (_obj = {
    amountDollars: (0, _object.computed)("unit_amount", {
      get() {
        return parseFloat(this.get("unit_amount") / 100).toFixed(2);
      },
      set(key, value) {
        const decimal = parseFloat(value) * 100;
        this.set("unit_amount", decimal);
        return value;
      }
    }),
    billingInterval(interval) {
      return interval || "one-time";
    },
    subscriptionRate(amountDollars, currency, interval) {
      return `${amountDollars} ${currency.toUpperCase()} / ${interval}`;
    }
  }, (_applyDecoratedDescriptor(_obj, "billingInterval", [_dec], Object.getOwnPropertyDescriptor(_obj, "billingInterval"), _obj), _applyDecoratedDescriptor(_obj, "subscriptionRate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "subscriptionRate"), _obj)), _obj)));
  var _default = _exports.default = Plan;
});