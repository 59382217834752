define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-products-index", ["exports", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/admin-product", "I18n", "@ember/object", "@ember/service"], function (_exports, _route, _adminProduct, _I18n, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _route.default.extend((_obj = {
    dialog: (0, _service.inject)(),
    model() {
      return _adminProduct.default.findAll();
    },
    destroyProduct(product) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_subscriptions.admin.products.operations.destroy.confirm"),
        didConfirm: () => {
          return product.destroy().then(() => {
            this.controllerFor("adminPluginsDiscourseSubscriptionsProductsIndex").get("model").removeObject(product);
          }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n")));
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "destroyProduct", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyProduct"), _obj)), _obj));
});