define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-products-show-plans-show", ["exports", "discourse-common/utils/decorators", "discourse/lib/url", "@ember/controller", "@ember/object/computed", "discourse/lib/ajax-error"], function (_exports, _decorators, _url, _controller, _computed, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const RECURRING = "recurring";
  const ONE_TIME = "one_time";
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model.plan.metadata.group_name"), _dec2 = (0, _decorators.default)("model.groups"), _dec3 = (0, _decorators.default)("model.plan.isNew"), _dec4 = (0, _decorators.default)("model.product.id"), (_obj = {
    // Also defined in settings.
    selectedCurrency: (0, _computed.alias)("model.plan.currency"),
    selectedInterval: (0, _computed.alias)("model.plan.interval"),
    selectedGroup(groupName) {
      return groupName || "no-group";
    },
    availableGroups(groups) {
      return [{
        id: null,
        name: "no-group"
      }, ...groups];
    },
    currencies() {
      return [{
        id: "AUD",
        name: "AUD"
      }, {
        id: "CAD",
        name: "CAD"
      }, {
        id: "EUR",
        name: "EUR"
      }, {
        id: "GBP",
        name: "GBP"
      }, {
        id: "USD",
        name: "USD"
      }, {
        id: "INR",
        name: "INR"
      }, {
        id: "BRL",
        name: "BRL"
      }, {
        id: "DKK",
        name: "DKK"
      }, {
        id: "SGD",
        name: "SGD"
      }, {
        id: "JPY",
        name: "JPY"
      }];
    },
    availableIntervals() {
      return [{
        id: "day",
        name: "day"
      }, {
        id: "week",
        name: "week"
      }, {
        id: "month",
        name: "month"
      }, {
        id: "year",
        name: "year"
      }];
    },
    planFieldDisabled(isNew) {
      return !isNew;
    },
    productId(id) {
      return id;
    },
    redirect(product_id) {
      _url.default.redirectTo(`/admin/plugins/discourse-subscriptions/products/${product_id}`);
    },
    actions: {
      changeRecurring() {
        const recurring = this.get("model.plan.isRecurring");
        this.set("model.plan.type", recurring ? ONE_TIME : RECURRING);
        this.set("model.plan.isRecurring", !recurring);
      },
      createPlan() {
        if (this.model.plan.metadata.group_name === "no-group") {
          this.set("model.plan.metadata.group_name", null);
        }
        this.get("model.plan").save().then(() => this.redirect(this.productId)).catch(_ajaxError.popupAjaxError);
      },
      updatePlan() {
        if (this.model.plan.metadata.group_name === "no-group") {
          this.set("model.plan.metadata.group_name", null);
        }
        this.get("model.plan").update().then(() => this.redirect(this.productId)).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "selectedGroup", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectedGroup"), _obj), _applyDecoratedDescriptor(_obj, "availableGroups", [_dec2], Object.getOwnPropertyDescriptor(_obj, "availableGroups"), _obj), _applyDecoratedDescriptor(_obj, "currencies", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "currencies"), _obj), _applyDecoratedDescriptor(_obj, "availableIntervals", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "availableIntervals"), _obj), _applyDecoratedDescriptor(_obj, "planFieldDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "planFieldDisabled"), _obj), _applyDecoratedDescriptor(_obj, "productId", [_dec4], Object.getOwnPropertyDescriptor(_obj, "productId"), _obj)), _obj)));
});