define("discourse/plugins/discourse-subscriptions/discourse/controllers/subscribe-show", ["exports", "@ember/controller", "discourse/plugins/discourse-subscriptions/discourse/models/subscription", "discourse/plugins/discourse-subscriptions/discourse/models/transaction", "I18n", "@ember/object/computed", "discourse-common/utils/decorators", "@ember/service"], function (_exports, _controller, _subscription, _transaction, _I18n, _computed, _decorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model.product.repurchaseable", "model.product.subscribed"), (_obj = {
    dialog: (0, _service.inject)(),
    router: (0, _service.inject)(),
    selectedPlan: null,
    promoCode: null,
    cardholderName: null,
    cardholderAddress: {
      line1: null,
      city: null,
      state: null,
      country: null,
      postalCode: null
    },
    isAnonymous: (0, _computed.not)("currentUser"),
    isCountryUS: false,
    isCountryCA: false,
    init() {
      this._super(...arguments);
      this.set("stripe", Stripe(this.siteSettings.discourse_subscriptions_public_key));
      const elements = this.get("stripe").elements();
      this.set("cardElement", elements.create("card", {
        hidePostalCode: true
      }));
      this.set("isCountryUS", this.cardholderAddress.country === "US");
      this.set("isCountryCA", this.cardholderAddress.country === "CA");
    },
    alert(path) {
      this.dialog.alert(_I18n.default.t(`discourse_subscriptions.${path}`));
    },
    canPurchase(repurchaseable, subscribed) {
      if (!repurchaseable && subscribed) {
        return false;
      }
      return true;
    },
    createSubscription(plan) {
      return this.stripe.createToken(this.get("cardElement"), {
        name: this.cardholderName,
        // Recommended by Stripe
        address_line1: this.cardholderAddress.line1 ?? "",
        address_city: this.cardholderAddress.city ?? "",
        address_state: this.cardholderAddress.state ?? "",
        address_zip: this.cardholderAddress.postalCode ?? "",
        address_country: this.cardholderAddress.country // Recommended by Stripe
      }).then(result => {
        if (result.error) {
          this.set("loading", false);
          return result;
        } else {
          const subscription = _subscription.default.create({
            source: result.token.id,
            plan: plan.get("id"),
            promo: this.promoCode,
            cardholderName: this.cardholderName,
            cardholderAddress: this.cardholderAddress
          });
          return subscription.save();
        }
      });
    },
    handleAuthentication(plan, transaction) {
      return this.stripe.confirmCardPayment(transaction.payment_intent.client_secret).then(result => {
        if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
          return result;
        } else {
          this.set("loading", false);
          this.dialog.alert(result.error.message || result.error);
          return result;
        }
      });
    },
    _advanceSuccessfulTransaction(plan) {
      this.alert("plans.success");
      this.set("loading", false);
      this.router.transitionTo(plan.type === "recurring" ? "user.billing.subscriptions" : "user.billing.payments", this.currentUser.username.toLowerCase());
    },
    actions: {
      changeCountry(country) {
        this.set("cardholderAddress.country", country);
        this.set("isCountryUS", country === "US");
        this.set("isCountryCA", country === "CA");
      },
      changeState(stateOrProvince) {
        this.set("cardholderAddress.state", stateOrProvince);
      },
      stripePaymentHandler() {
        this.set("loading", true);
        const plan = this.get("model.plans").filterBy("id", this.selectedPlan).get("firstObject");
        const cardholderAddress = this.cardholderAddress;
        const cardholderName = this.cardholderName;
        if (!plan) {
          this.alert("plans.validate.payment_options.required");
          this.set("loading", false);
          return;
        }
        if (!cardholderName) {
          this.alert("subscribe.invalid_cardholder_name");
          this.set("loading", false);
          return;
        }
        if (!cardholderAddress.country) {
          this.alert("subscribe.invalid_cardholder_country");
          this.set("loading", false);
          return;
        }
        if (cardholderAddress.country === "US" && !cardholderAddress.state) {
          this.alert("subscribe.invalid_cardholder_state");
          this.set("loading", false);
          return;
        }
        if (cardholderAddress.country === "CA" && !cardholderAddress.state) {
          this.alert("subscribe.invalid_cardholder_province");
          this.set("loading", false);
          return;
        }
        let transaction = this.createSubscription(plan);
        transaction.then(result => {
          if (result.error) {
            this.dialog.alert(result.error.message || result.error);
          } else if (result.status === "incomplete" || result.status === "open") {
            const transactionId = result.id;
            const planId = this.selectedPlan;
            this.handleAuthentication(plan, result).then(authenticationResult => {
              if (authenticationResult && !authenticationResult.error) {
                return _transaction.default.finalize(transactionId, planId).then(() => {
                  this._advanceSuccessfulTransaction(plan);
                });
              }
            });
          } else {
            this._advanceSuccessfulTransaction(plan);
          }
        }).catch(result => {
          this.dialog.alert(result.jqXHR.responseJSON.errors[0] || result.errorThrown);
          this.set("loading", false);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "canPurchase", [_dec], Object.getOwnPropertyDescriptor(_obj, "canPurchase"), _obj)), _obj)));
});