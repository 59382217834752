define("discourse/plugins/discourse-subscriptions/discourse/helpers/format-unix-date", ["discourse-common/lib/helpers", "discourse/lib/formatter", "@ember/template"], function (_helpers, _formatter, _template) {
  "use strict";

  (0, _helpers.registerUnbound)("format-unix-date", function (timestamp) {
    if (timestamp) {
      const date = new Date(moment.unix(timestamp).format());
      return new _template.htmlSafe((0, _formatter.autoUpdatingRelativeAge)(date, {
        format: "medium",
        title: true,
        leaveAgo: true
      }));
    }
  });
});