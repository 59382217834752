define("discourse/plugins/discourse-subscriptions/discourse/templates/user/billing", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DSection
    @pageClass="user-billing"
    @class="user-secondary-navigation"
    @scrollTop="false"
  >
    <MobileNav
      @class="activity-nav"
      @desktopClass="action-list nav-stacked"
      @currentPath={{router._router.currentPath}}
    >
      <li>
        <LinkTo @route="user.billing.subscriptions">
          {{i18n "discourse_subscriptions.navigation.subscriptions"}}
        </LinkTo>
      </li>
  
      <li>
        <LinkTo @route="user.billing.payments">
          {{i18n "discourse_subscriptions.navigation.payments"}}
        </LinkTo>
      </li>
    </MobileNav>
  </DSection>
  
  <section class="user-content">
    {{outlet}}
  </section>
  */
  {
    "id": "IBZ2T+Sp",
    "block": "[[[8,[39,0],null,[[\"@pageClass\",\"@class\",\"@scrollTop\"],[\"user-billing\",\"user-secondary-navigation\",\"false\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@class\",\"@desktopClass\",\"@currentPath\"],[\"activity-nav\",\"action-list nav-stacked\",[30,0,[\"router\",\"_router\",\"currentPath\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@route\"],[\"user.billing.subscriptions\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"discourse_subscriptions.navigation.subscriptions\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@route\"],[\"user.billing.payments\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"discourse_subscriptions.navigation.payments\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-content\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `router` property path was used in the `discourse/plugins/discourse-subscriptions/discourse/templates/user/billing.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.router}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-section\",\"mobile-nav\",\"link-to\",\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/user/billing.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});