define("discourse/plugins/discourse-subscriptions/discourse/controllers/subscribe-index", ["exports", "@ember/controller", "discourse-common/utils/decorators", "discourse/models/user"], function (_exports, _controller, _decorators, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)(), (_obj = {
    isLoggedIn() {
      return _user.default.current();
    }
  }, (_applyDecoratedDescriptor(_obj, "isLoggedIn", [_dec], Object.getOwnPropertyDescriptor(_obj, "isLoggedIn"), _obj)), _obj)));
});