define("discourse/plugins/discourse-subscriptions/discourse/components/payment-options", ["exports", "@ember/component", "discourse-common/utils/decorators", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _decorators, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p>
    {{i18n "discourse_subscriptions.plans.select"}}
  </p>
  
  <div class="subscribe-buttons">
    {{#each orderedPlans as |plan|}}
      <PaymentPlan
        @plan={{plan}}
        @selectedPlan={{selectedPlan}}
        @clickPlan={{action "clickPlan"}}
      />
    {{/each}}
  </div>
  */
  {
    "id": "lqNfgwxq",
    "block": "[[[10,2],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"discourse_subscriptions.plans.select\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"subscribe-buttons\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"orderedPlans\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@plan\",\"@selectedPlan\",\"@clickPlan\"],[[30,1],[30,0,[\"selectedPlan\"]],[28,[37,4],[[30,0],\"clickPlan\"],null]]],null],[1,\"\\n\"]],[1]],null],[13],[1,[28,[32,0],[\"[[\\\"The `orderedPlans` property path was used in the `discourse/plugins/discourse-subscriptions/discourse/components/payment-options.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.orderedPlans}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `selectedPlan` property path was used in the `discourse/plugins/discourse-subscriptions/discourse/components/payment-options.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.selectedPlan}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"plan\"],false,[\"i18n\",\"each\",\"-track-array\",\"payment-plan\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/payment-options.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_dec = (0, _decorators.default)("plans"), (_obj = {
    orderedPlans(plans) {
      if (plans) {
        return plans.sort((a, b) => a.unit_amount > b.unit_amount ? 1 : -1);
      }
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.plans && this.plans.length === 1) {
        this.set("selectedPlan", this.plans[0].id);
      }
    },
    actions: {
      clickPlan(plan) {
        this.set("selectedPlan", plan.id);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "orderedPlans", [_dec], Object.getOwnPropertyDescriptor(_obj, "orderedPlans"), _obj)), _obj))));
});